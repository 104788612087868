import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 핀다이렉트는 최상의 데이터로밍 경험 제공 중",
  "| 해외에서도 OTT 보고, 음악듣고… 데이터 걱정 없는 여행, 핀다이렉트에서는 가능 “로컬망 제휴 확대”",
  "| 24시간 로밍전문 고객센터, 평균 응답시간 30초, 143개국 서비스 제공",
  "| Qos 제한 없이 처음 속도 그대로 무제한 상품 출시… 베트남, 일본, 대만 순으로 순차적 런칭",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/37/37_img1.png" style={{ width: "300px", margin: "auto" }} alt="Figure2" />
      </div>
      <div className="desc">&lt;(위)핀다이렉트 데이터로밍&gt;</div>
    </div>
    <p>
      <br />
      <br />
      스테이지파이브(대표 서상원)가 25일 핀다이렉트 데이터로밍 서비스의 품질향상을 위해 로컬망 직접 제휴를 확대한다고 밝혔다. 이는
      기존에도 고객 만족도 높았던 핀다이렉트의 데이터로밍을 확대하여, 해외 현지에서도 데이터를 많이 사용하는 MZ 고객 니즈에
      발맞추는 행보다. 금번 제휴를 통해 스테이지파이브는 현지 통신사와 다이렉트로 제휴하여 Qos 없는 진짜 무제한 데이터로밍
      서비스를 제공한다. OTT, 음악 스트리밍 등 해외여행 중에도 데이터 속도와 가격 걱정에서 벗어날 수 있는 고객관점의 상품을
      선보이는 것이다. 또한, 고객에게 제공하는 가격은 탁월하게 낮춰 혜택을 극대화한다는 복안이다.
      <br />
      <br />
      구체적으로, 현지 통신사와의 직접 제휴를 통하면 보다 광범위한 데이터 대역폭을 확보할 수 있어 트래픽이 늘어도 무리 없이 접속
      가능하다. 또한, 별도의 제한 없이 현지인들과 같은 속도로 데이터를 즐길 수 있다. 품질관리 이슈 등이 발생하면 양사 간 즉시 공유
      및 피드백이 가능하여 보다 빠르게 서비스를 개선할 수 있다. 서비스의 질은 개선되는데 반해 직거래 방식이기 때문에 고객에게
      제공하는 가격은 더욱 낮출 수 있다. 현재 핀다이렉트에서 제공하는 베트남 로컬망 데이터로밍 상품의 경우 이통3사 대비 1일 기준
      60%, 5일 기준 88% 이상 저렴한 가격이다.
      <br />
      <br />
      <div className="descImage">
        <div className="img">
          <img src="/images/press/37/37_img3.png" alt="Figure2" />
        </div>
        <div className="desc">&lt;(위)핀다이렉트 로밍 스펙 및 가격 비교표&gt;</div>
      </div>
      <br />
      <br />
      핀다이렉트에서는 상기 기술한 고품질의 데이터로밍 상품을 속도제한 없이 eSIM 전용으로 제공한다. 기본제공 데이터+Qos 데이터로
      구성되어 판매되던 기존 시장 주류 로밍상품이 아닌, 처음 속도 그대로 무제한 사용할 수 있는 상품*인 것이 특장점이다. 때문에
      진정한 의미의 무제한 상품이라고 볼 수 있다.
      <br />
      <br />
      *통상적으로 기본 제공되는 LTE 속도와 Qos 속도는 큰 차이가 있음. 대게의 경우 GB 단위의 기본데이터 소진 후 384kbps 이하의 Qos
      속도로 제한됨. 이는 멀티미디어 사용이나 이미지 전송 등에 권장되지 않는 속도.
      <br />
      <br />
      스테이지파이브는 그간 데이터로밍 고객들의 페인포인트를 해결하는데 집중해 왔다. 24시간 로밍전문 고객만족센터를 운영하고, 평균
      30초 이내로 로밍고객에게 상담을 지원했다. 해외 도착 후 와이파이를 잡아 로밍을 활성화해야 했던 번거로움을 불식시키고자,
      국내에서 미리 활성화 후 출국할 수 있도록 프로세스도 개선했다. 로밍 위젯도 출시하여 내가 해외에서 사용한 실시간 데이터를
      위젯으로 편리하게 확인하게끔 한 것도 특장점이다.
      <br />
      <br />
      <div className="descImage">
        <div className="img">
          <img src="/images/press/37/37_img2.png" alt="Figure2" />
        </div>
        <div className="desc">&lt;(위) 핀다이렉트 로밍 위젯&gt;</div>
      </div>
      <br />
      <br />
      스테이지파이브 관계자는 “로밍은 해외여행 시 필수적인 요소”라며, “고객이 원하는 편리함과 혜택을 담은 로컬망 무제한 데이터
      로밍 상품을 선보이게 되어 기쁘다”고 밝혔다.
      <br />
      <br />
      스테이지파이브는 금번 로컬망 직접 제휴를 확대하여 베트남뿐 아니라 일본과 대만 등 기타 국가까지 순차적으로 상품을 오픈할 수
      있도록 준비 중이다.
      <br />
      <br />
      한편, 핀다이렉트 데이터로밍 상품은 지난 22년 12월 해외여행 1등 플랫폼 인터파크, 여행앱 사용자 1위(700만명)인 트리플과 함께
      그랜드 오픈하여 업계 이목을 끌은 바 있다.
      <br />
    </p>
  </div>
)

const press37 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2023년 5월 23일 조간 즉시배포 가능"
      pageInfo="(사진자료 메일 첨부)"
      title="해외 어디에 있어도 핀다이렉트 로밍 고객은 이상 무!"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press37
